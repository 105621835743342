
import { defineComponent, Ref, ref, onUpdated , onMounted, onBeforeMount, onUnmounted, watch } from "vue";
import { Modal } from "bootstrap";
import Api from "@/services/Api"
import Notificacao from "@/services/Notificacao"
import useAlert from "@/composables/Alert";
import useEmitter from "@/composables/Emmiter";

interface Marcas {
  id: number;
  nome: string;
}

interface Produto {
  nome: string | null;
  idMarca: number | null;
  idTipo: number | null;
  tipo: string | null
}

export default defineComponent({
  name: "EditaVeiculo",

  components: { },
  props: {
    fechaModal: Function,
    veiculos: Array,
  },

  setup(props) {

    const { showTimeAlert } = useAlert();
    const emitter = useEmitter();


    const loadingSalva: Ref<boolean> = ref(false);

    const veiculos: Ref<any[]> = ref([]);

    const arrayMarcas: Ref<Marcas[]> = ref([]);
    const arrayTipos: Ref<any[]> = ref([]);

    const produtoVeiculo: Ref<Produto[]> = ref([]);
    const produtoCopiado: Ref<Produto[]> = ref([]);

    const copiado: Ref<boolean> = ref(false);



    const tipoSelecionado: Ref<string> = ref('filtroOleo' );















    function teste() {
      console.log('aa',produtoVeiculo.value)
    }

    const getMarcaProduto = async() => arrayMarcas.value = <Marcas[]> (await Api.get('getMarcaProduto')).data.marcas
    const getTipoProduto = async() => arrayTipos.value = <any[]> (await Api.get('getTipoProduto')).data.tipos


    function adicionaProduto() {

      const tipo = arrayTipos.value.find(e=> e.nome === tipoSelecionado.value) 

      produtoVeiculo.value.push({ nome: '', idMarca: null, idTipo: tipo.id, tipo: tipo.nome })

    }

    function removeProduto(index: number) {

      produtoVeiculo.value.splice(index, 1)

    }

    function copiarProdutos() {
      copiado.value = !copiado.value
      produtoCopiado.value = produtoVeiculo.value;
    }

    function colarProdutos() {
      produtoVeiculo.value = produtoCopiado.value
    }

    async function carregaProduto() {

      if(!veiculos.value || veiculos.value.length !== 1) return;

      const { data } = await Api.get('carregaProduto', veiculos.value[0] )

      produtoVeiculo.value = data.produtos.map((e: Produto) => ({...e, idMarca: Number(e.idMarca), idTipo: Number(e.idTipo)}) );

    }

    async function salvaModificacao() {

      try {
        
        loadingSalva.value = true;

        const { data } = await Api.post('salvaModificacaoVeiculo', { veiculos: veiculos.value, produtos: produtoVeiculo.value})
  
        loadingSalva.value = false;

        if(!data.modificacaoSalva) return showTimeAlert("Não foi possível salvar a alteração!");
  
        enviaVeiculosEditados()
  
        showTimeAlert("A alteração foi salva com sucesso!");
        
      } catch (error) { showTimeAlert("Não foi possível salvar a alteração!"); } finally { loadingSalva.value = false; }

    }

    function enviaVeiculosEditados() {

      const produto = {
        filtroOleo: produtoVeiculo.value.filter((e)=> e.tipo == 'filtroOleo').length,
        filtroAr: produtoVeiculo.value.filter((e)=> e.tipo == 'filtroAr').length,
        filtroAc: produtoVeiculo.value.filter((e)=> e.tipo == 'filtroAc').length,
        filtroCombustivel: produtoVeiculo.value.filter((e)=> e.tipo == 'filtroCombustivel').length,
        filtros: produtoVeiculo.value.length,
      }

      emitter.emit("atualiza-lista-veiculos", { veiculos: veiculos.value, produtos: produto } )
    }

    watch(
      () => veiculos.value,
      () => carregaProduto()
    );


    onBeforeMount(() => {
      
    });

    onUpdated(async() =>{

      veiculos.value = props.veiculos as any;

    });

    onUnmounted(() =>{

    });

    onMounted(async() => {

      await getMarcaProduto()
      await getTipoProduto()
      adicionaProduto()
      
    })

    const dismiss = () => {

      produtoVeiculo.value = [];
      props.fechaModal && props.fechaModal();

    }


    return { dismiss, salvaModificacao, veiculos, arrayMarcas, tipoSelecionado, produtoVeiculo, adicionaProduto, removeProduto, copiarProdutos, colarProdutos, produtoCopiado, copiado, loadingSalva, teste,}
  }

})

