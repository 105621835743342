
import { defineComponent, Ref, ref, onMounted, onBeforeMount, watch } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import Api from "@/services/Api";
import useEmitter from "@/composables/Emmiter";
import EditaVeiculo from "./components/EditaVeiculo.vue";
import Title from "@/components/Title.vue"
import Button from "@/components/Button.vue"
import { Modal } from "bootstrap";
// import { maska } from "maska"

interface VehicleFilterType {
  marca: string;
  modelo: string;
  fipe: string;
  anoInicial: string;
  anoFinal: string;
  comFiltro: boolean | null;
}

export default defineComponent({
  name: "Vehicle",

  components: {
    Field,
    Form,
    ErrorMessage,
    EditaVeiculo,
    Title,
    Button,
  },

  setup() {
    const emitter = useEmitter();

    const maxLimitByPage = 25;

    const resultados: Ref<number> = ref(0);

    const vehicleLoad: Ref<boolean> = ref(false);
    const vehicleDetailLoad: Ref<boolean> = ref(false);

    const editarMultiplos: Ref<boolean> = ref(false);

    const VeiculoModal: Ref<any> = ref();
    const veiculosSelecionado: Ref<any[]> = ref([]);

    const vehiclesArray: Ref<any[]> = ref([]);
    const vehicleBrandArray: Ref<any[]> = ref([]);

    const total: Ref<number> = ref(0);
    const page: Ref<number | null> = ref(null);

    const vehicleFilter: Ref<VehicleFilterType> = ref({ marca: "", modelo: "", fipe: "", anoInicial: "", anoFinal: "", comFiltro: null });

    const fastFind: Ref<string> = ref('');


    const handlePage = async (paramPage: number) => await searchVehicle(paramPage, maxLimitByPage);

    async function searchVehicle(pageParam: number, limit: number, search: boolean = false) {

      vehicleLoad.value = true;

      const { data } = await Api.get("getVehiclesByPage", { page: pageParam, limit, ...vehicleFilter.value });

      page.value = pageParam;

      if (search) vehiclesArray.value = [];

      vehiclesArray.value = [...vehiclesArray.value, ...filtraVeiculos(data.veiculos)];

      vehicleLoad.value = false;
      
    }

    function filtraVeiculos(veiculos: any) {
      if (vehicleFilter.value.comFiltro === false) return veiculos.filter(({ filtros }) => Number(filtros) === 0);
      if (vehicleFilter.value.comFiltro === true) return veiculos.filter(({ filtros }) => Number(filtros) > 0);

      return veiculos;
    }

    async function getVehicleBrands() {
      const { data } = await Api.get("getAllvehicleBrand");
      vehicleBrandArray.value = data.marcas;
    }

    emitter.on("buscar-filtro-veiculo", (props: VehicleFilterType) => {
      vehicleFilter.value = props;

      searchVehicle(1, maxLimitByPage, true);

      buscaTotalVeiculos();
    });




    

    async function buscaTotalVeiculos() {
      const { data } = await Api.get("buscaTotalVeiculos", { ...vehicleFilter.value });

      resultados.value = data.resultados;

      console.log(data);
    }

    // ############ MODAL DE VEICULOS #############

    function mudaEditarMultiplos() {
      veiculosSelecionado.value = [];
      editarMultiplos.value = !editarMultiplos.value;

      if (editarMultiplos.value) return;

      vehiclesArray.value.forEach(e => (e.selecionado = false));
      veiculosSelecionado.value = [];
    }

    function selecionaVeiculo(veiculo) {
      veiculo.selecionado = !veiculo.selecionado;

      if (veiculo.selecionado) veiculosSelecionado.value.push(veiculo);
      else veiculosSelecionado.value = veiculosSelecionado.value.filter(e => e.selecionado === true);
    }

    function escolheVeiculo(veiculo) {
      veiculosSelecionado.value = [veiculo];
      abreVeiculoModal();
    }

    async function abreVeiculoModal() {
      VeiculoModal.value.show();
    }

    async function fechaVeiculoModal() {
      VeiculoModal.value.hide();
    }

    // Função para atualizar a lista de veiculos
    emitter.on("atualiza-lista-veiculos", ({ veiculos, produtos}) => {

      vehiclesArray.value.forEach((veiculo)=>{
        veiculos.forEach((veiculoEditado) => {

          if(veiculo.id === veiculoEditado.id) {

            veiculo.filtroOleo = produtos.filtroOleo
            veiculo.filtroAr = produtos.filtroAr
            veiculo.filtroAc = produtos.filtroAc
            veiculo.filtroCombustivel = produtos.filtroCombustivel

            veiculo.filtros = produtos.filtros

          }

        })
      })

    });

    async function resetObject(obj) {
      for (const prop in obj) {
        obj[prop] = typeof obj[prop] === "object" ? {} : "";
      }
    }

    watch(
      () => fastFind.value,
      async (newVal) => {
        resetObject(vehicleFilter.value);
        if (newVal && newVal.length > 2) {
          vehicleFilter.value.modelo = newVal;
          await searchVehicle(1, maxLimitByPage, true);
        }
        if (!vehiclesArray.value.length && newVal) {
          resetObject(vehicleFilter.value);
          vehicleFilter.value.fipe = newVal;
          await searchVehicle(1, maxLimitByPage, true);
        }
        if(!fastFind.value) await searchVehicle(1, maxLimitByPage, true);
      }
    );

    // ############ MODAL DE VEICULOS #############

    onBeforeMount(async () => {
      await getVehicleBrands();
      VeiculoModal.value = new Modal(document.querySelector("#EditaVeiculo"));
    });

    onMounted(() => {
      const observer = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting) handlePage(page.value ? page.value + 1 : 1);
      }).observe(document.querySelector("#load") as any);
      buscaTotalVeiculos();
    });

    return {
      console,
      resultados,
      page,
      maxLimitByPage,
      total,
      vehiclesArray,
      handlePage,
      searchVehicle,
      vehicleLoad,
      vehicleFilter,
      vehicleDetailLoad,
      vehicleBrandArray,
      history,
      abreVeiculoModal,
      fechaVeiculoModal,
      veiculosSelecionado,
      editarMultiplos,
      selecionaVeiculo,
      escolheVeiculo,
      mudaEditarMultiplos,
      fastFind
    };
  },
});
